import Cookies from 'js-cookie';
import client from 'gql/client';
import { getCurrentUserQuery } from 'gql/currentUser/currentUser.gql';

interface CallingUser {
  userName: string;
  impersonatorUserName?: string;
}

export const storeUsername = (userName: string) => {
  sessionStorage.setItem('username', userName);
  Cookies.set('username', userName, {
    path: '/',
    domain: '.verisure.com',
    secure: true,
  });
};

export const refreshCurrentUser = async (): Promise<string | undefined> => {
  try {
    const result = await client.query<{ callingUser: CallingUser }>({
      query: getCurrentUserQuery,
      variables: {},
    });
    const {
      data: {
        callingUser: { userName, impersonatorUserName },
      },
    } = result;
    storeUsername(userName);
    if (impersonatorUserName) {
      sessionStorage.setItem('operatorId', impersonatorUserName);
      Cookies.set('operatorId', impersonatorUserName, {
        path: '/',
        domain: '.verisure.com',
        secure: true,
      });
    }
    return userName;
  } catch (e) {
    if (!(e as any).networkError) {
      throw e;
    }
  }
};
