import { useState } from 'react';
import { Base64 } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { ga } from 'mypages-library';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { parseError } from 'common/errors/errorUtils';
import { getLocale } from 'common/languageUtil';
import { authenticateWildfly } from 'common/wildflyAuthentication';
import { navigateTo } from 'common/navigationUtil';
import { simpleSentryLog } from 'common/utilities/simpleSentryLog';
import { refreshCurrentUser, storeUsername } from 'gql/currentUser/currentUser';

export const useAuthenticate = () => {
  const [errorBody, setErrorBody] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMultiFactorAuthentication, setIsMultiFactorAuthentication] = useState(false);
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language || 'se';
  const LOGIN_PATH = '/auth/login';

  const track = eventLabel => {
    ga.trackEvent({
      eventCategory: 'site login',
      eventAction: 'site login',
      eventLabel,
    });
  };

  const handleError = (e, label) => {
    setErrorBody({ errorCode: e.errorCode, message: e.message, verified: e.verified });
    setIsLoading(false);
    track(label);
  };

  const handleSentryLogging = e => {
    simpleSentryLog('e-commerce redirect', { e }, { giid: 'unknown' });
  };

  const handleAuthenticateResponse = async response => {
    if (response.status !== 200) {
      const errorCodeReceived = await parseError(response);
      const error = new Error(errorCodeReceived.message);
      error.verified = errorCodeReceived.verified;
      error.errorCode = errorCodeReceived.errorCode;

      throw error;
    }
    return response.json();
  };

  const routeLoginFlow = (data, email, setSuccessfulLogin, singleSignOn = false) => {
    track('success');
    if (data.stepUpToken != null) {
      setIsMultiFactorAuthentication(true);
      ga.trackEvent({
        eventCategory: 'site login',
        eventAction: 'mfa started',
      });
      return Promise.resolve();
    }
    return Promise.resolve()
      .then(() => authenticateWildfly(data, email, getLocale(getLanguage()), setErrorBody, setIsLoading, singleSignOn))
      .then(() => {
        navigateTo(getLanguage());
        setSuccessfulLogin(true);
      });
  };

  const getHeaders = (email, password, isOperator) => {
    if (!isOperator) {
      return {
        authorization: `Basic ${Base64.encode(`${email}:${password}`)}`,
        accept: 'application/json',
        APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
      };
    }

    const [username, impersonationToken] = email.split('*');
    return {
      authorization: `Basic ${Base64.encode(`${username}:${password}`)}`,
      accept: 'application/json',
      APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
      impersonationToken,
    };
  };

  const authenticate = async (email, password, setSuccessfulLogin, redirectToEcommerce) => {
    if (errorBody !== null) {
      setErrorBody(null);
    }
    const isOperator = email.includes('*') && !email.includes('@');
    const headers = getHeaders(email, password, isOperator);

    setIsLoading(true);
    try {
      const loginResponse = await fetchWithFailover(LOGIN_PATH, {
        method: 'POST',
        credentials: 'include',
        headers,
      });
      const response = await handleAuthenticateResponse(loginResponse);
      if (isOperator) {
        await refreshCurrentUser();
      } else {
        // No need to fetch current user since we know the username already. Just store it.
        storeUsername(email);
      }
      await routeLoginFlow(response, email, setSuccessfulLogin);
    } catch (e) {
      handleError(e, 'fail');
      if (redirectToEcommerce) {
        handleSentryLogging();
      }
    }
  };
  const authenticateSingleSignOn = async email => {
    if (errorBody !== null) {
      setErrorBody(null);
    }
    setIsLoading(true);
    try {
      const loginResponse = await fetchWithFailover(LOGIN_PATH, {
        method: 'POST',
        credentials: 'include',
        headers: {
          APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
        },
      });
      const response = await handleAuthenticateResponse(loginResponse);
      await refreshCurrentUser();
      await routeLoginFlow(response, email, () => null, true);
    } catch (e) {
      handleError(e, 'Single sign on fail');
    }
    setIsLoading(false);
  };

  const authenticateLoginTicket = async ticket => {
    setErrorBody(null);
    setIsLoading(true);

    if (!ticket) {
      setIsLoading(false);
      setErrorBody({ message: 'No ticket provided' });
      return;
    }

    try {
      const loginResponse = await fetchWithFailover(LOGIN_PATH, {
        method: 'POST',
        credentials: 'include',
        headers: {
          APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
          ACCEPT: 'application/json',
          AUTHORIZATION: `LOGIN-TICKET ${ticket}`,
        },
      });
      const response = await handleAuthenticateResponse(loginResponse);

      // Set runningInApp to true when we have a valid ticket
      sessionStorage.setItem('runningInApp', true);

      const userName = await refreshCurrentUser();

      await routeLoginFlow(response, userName, () => null, true);
    } catch (e) {
      simpleSentryLog('Login with ticket fail', { e });
      handleError(e, 'Login with ticket fail');
    }
    setIsLoading(false);
  };

  return {
    isMultiFactorAuthentication,
    isLoading,
    errorBody,
    authenticate,
    authenticateSingleSignOn,
    authenticateLoginTicket,
  };
};
