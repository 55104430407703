import React from 'react';
import { Button } from 'mypages-library';
import congratulation from 'images/wbaccount/congratulations.png';
import { t } from 'i18n/translate';
import GenericWebAccountStatus from 'components/WebAccountCreated/GenericWebAccountStatus';
import styles from './AccountStatusSuccess.module.scss';

interface AccountStatusSuccessProps {
  bodyTranslationKey: string;
  dataTestId: string;
}

const AccountStatusSuccess: React.FC<AccountStatusSuccessProps> = ({ bodyTranslationKey, dataTestId }) => {
  const navigateToLogin = () => (window.location.href = 'https://app.verisure.com/home');

  return (
    <GenericWebAccountStatus
      header={t('MPL_CREATE_ACCOUNT_VERIFY_TITLE')}
      body={t(bodyTranslationKey)}
      image={congratulation}
      altText="Web account status"
      dataTestId={dataTestId}
    >
      <Button
        text={t('MPL_CREATE_ACCOUNT_VERIFY_LOG_IN')}
        primary
        onClick={navigateToLogin}
        className={styles.loginBtn}
        dataTestId="fromInviteSuccessLoginBtn"
      />
    </GenericWebAccountStatus>
  );
};

export default AccountStatusSuccess;
