import React, { useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  Checkbox,
  Country,
  InternationalPhonePicker,
  LoadingSpinner,
  appConstants,
  findPreselectedCountryFromLanguage,
  formatPhoneForApi,
  getLocaleStringFromLanguage,
  tempSupportedDialCodes,
  validation,
} from 'mypages-library';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/Input/Input';
import { t } from 'i18n/translate';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import PasswordInput from 'components/Input/PasswordInput';
import WebAccountCreatedFromInvitation from 'components/WebAccountCreated/WebAccountFromInvitation';
import CountryPicker from 'components/CreateAccount/CountrySelector/CountrySelector';
import WebAccountInvalidInvite from 'components/WebAccountInvalidInvite/WebAccountInvalidInvite';
import WebAccountCreated from 'components/WebAccountCreated/WebAccountCreated';

import { ReCaptcha } from 'components/common/ReCaptcha/ReCaptcha';
import LanguageMenu from 'components/LanguageMenu/LanguageMenu';
import useNavigateOrClose from 'common/hooks/useNavigateOrClose';
import { refreshSessionStorage } from 'common/hooks/useInitialize';
import SidePanel from 'common/SidePanel/SidePanel';
import { useConfig } from 'common/hooks/useConfig';
import CookieInfo from 'components/common/CookieInfo/CookieInfo';
import { useCookieBannerState } from 'common/hooks/useCookieBannerState';
import InputValidation from '../Input/InputValidation';
import {
  isPasswordValid,
  passwordAndRepeatMatch,
  getPasswordRequirements,
  getCurrentPasswordErrors,
} from '../Input/passwordValidationUtil';
import TermsAndCondition from './TermsAndConditions/TermsAndCondition';
import useGetTermsAndConditions from './TermsAndConditions/useGetTermsAndConditions';
import styles from './CreateAccount.module.scss';
import { getDealerSettings } from './TermsAndConditions/privacyUtils';
import useCreateAccount, { CreateAccountForm } from './useCreateAccount';
import { useIOSfont } from './useIOSfont';

export type LocaleToCountryStringMappingEntries = [
  keyof typeof localeToCountryStringMapping,
  typeof localeToCountryStringMapping[keyof typeof localeToCountryStringMapping]
];

export const localeToCountryStringMapping = {
  nl_BE: 'België',
  fr_BE: 'Belgique',
  da_DK: 'Danmark',
  de_DE: 'Deutschland',
  fr_FR: 'France',
  nl_NL: 'Nederland',
  no_NO: 'Norge',
  fi_FI: 'Suomi',
  sv_SE: 'Sverige',
  en_GB: 'United Kingdom',
} as const;

const CreateAccount: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  refreshSessionStorage();
  const { navigateOrClose, runningInApp } = useNavigateOrClose();

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [agree, setAgree] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null);
  const [selectedCountryPhone, setSelectedCountryPhone] = useState<Country>(
    findPreselectedCountryFromLanguage(language)
  );
  const [locale, setLocale] = useState<string>(getLocaleStringFromLanguage(language));
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { cookieBannerVisible } = useCookieBannerState();

  const handleError = (errorMessage: string) => {
    setErrorMsg(errorMessage);
  };
  const {
    isFromInvitation,
    submitCreateAccount,
    webAccountCreated,
    invalidInvite,
    isCreatingAccount,
    isLoadingWebaccountInfo,
  } = useCreateAccount(setEmail, setPhoneNumber, handleError);

  const { isNameValid, isPhoneNumberValid, isEmailValid } = validation;
  const { FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, NAME_MIN_LENGTH, isIos } = appConstants;
  const firstNameError = firstName?.length > 0 && !isNameValid(firstName);
  const firstNameErrorMsg = firstNameError ? t('MPL_CREATE_ACCOUNT_NAME_VALIDATION_ERROR') : '';
  const lastNameError = lastName?.length > 0 && !isNameValid(lastName);
  const lastNameErrorMsg = lastNameError ? t('MPL_CREATE_ACCOUNT_NAME_VALIDATION_ERROR') : '';
  const phoneError = phoneNumber?.length > 0 && !isPhoneNumberValid(phoneNumber);
  const emailError = email?.length > 0 && !isEmailValid(email);
  const emailErrorMsg = emailError ? t('MPL_CREATE_ACCOUNT_INVALID_EMAIL') : '';
  const passwordError = password?.length > 0 && !isPasswordValid(password);
  const repeatPasswordError = repeatPassword?.length > 0 && !passwordAndRepeatMatch(password, repeatPassword);
  const repeatPasswordErrorMsg = repeatPasswordError ? t('MPL_PASSWORD_VALIDATION_PASSWORDS_NOT_MATCHING') : '';
  useIOSfont();

  const openPrivacyPolicy = async () => {
    const { privacyPolicy, errorText } = await getDealerSettings(locale);
    if (errorText) {
      setErrorMsg(errorText);
    } else {
      const target = isIos ? '_self' : '_blank';
      window.open(privacyPolicy, target);
    }
  };
  const [showTerms, setShowTerms] = useState(false);
  const {
    config: { reCaptchaSiteKey, apiUrl },
  } = useConfig();
  const { getAgreementByLocale, error: agreementError } = useGetTermsAndConditions(apiUrl);

  const getFormData = (): CreateAccountForm => ({
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    mobilePhone: isFromInvitation ? phoneNumber : formatPhoneForApi(phoneNumber, selectedCountryPhone),
    email,
    password,
    locale: getLocaleStringFromLanguage(language),
    recaptchaResponse,
  });

  const validForm =
    firstName?.length > 0 &&
    !firstNameError &&
    lastName?.length > 0 &&
    !lastNameError &&
    phoneNumber?.length > 0 &&
    !phoneError &&
    email?.length > 0 &&
    !emailError &&
    password?.length > 0 &&
    !passwordError &&
    repeatPassword?.length > 0 &&
    !repeatPasswordError &&
    agree &&
    (isFromInvitation ? true : recaptchaResponse);

  const disableSubmit = !validForm || isCreatingAccount;

  const handleSubmit = async () => {
    setErrorMsg(null);

    await submitCreateAccount(getFormData());
  };

  const onSelectionFieldChange = (selected: string) => {
    if (agree) {
      setAgree(false);
    }
    setLocale(selected);
  };

  if (webAccountCreated) {
    return isFromInvitation ? <WebAccountCreatedFromInvitation /> : <WebAccountCreated email={email} />;
  }

  if (invalidInvite) {
    return <WebAccountInvalidInvite />;
  }

  return (
    <>
      {isLoadingWebaccountInfo ? (
        <LoadingSpinner centered isLoading={isLoadingWebaccountInfo} />
      ) : (
        <div className={styles.loginWrapper}>
          <main className={styles.loginContent}>
            <SubsidiaryAppLogo />
            <div className={styles.inputContainer}>
              <Input
                onChange={value => setFirstName(value)}
                placeholder={t('MPL_CREATE_ACCOUNT_FIRSTNAME')}
                value={firstName}
                dataTestId="firstNameInput"
                disabled={isCreatingAccount}
                maxLength={FIRST_NAME_MAX_LENGTH}
                minLength={NAME_MIN_LENGTH}
                hasError={firstNameError}
                errorMessage={firstNameErrorMsg}
                focus
              />
              <Input
                onChange={value => setLastName(value)}
                placeholder={t('MPL_CREATE_ACCOUNT_LASTNAME')}
                value={lastName}
                dataTestId="lastNameInput"
                disabled={isCreatingAccount}
                maxLength={LAST_NAME_MAX_LENGTH}
                minLength={NAME_MIN_LENGTH}
                hasError={lastNameError}
                errorMessage={lastNameErrorMsg}
              />
              <InternationalPhonePicker
                setSelectedCountryPhone={setSelectedCountryPhone}
                selectedCountryPhone={selectedCountryPhone}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                errorMessage={phoneError ? t('MPL_INVALID_PHONE_NUMBER') : ''}
                phoneError={phoneError}
                disabled={isFromInvitation}
                popOverMessage={isFromInvitation ? t('MPL_CREATE_ACCOUNT_MOBILE_PHONE_NON_EDITABLE_HEADER') : ''}
                popOverDescription={isFromInvitation ? t('MPL_CREATE_ACCOUNT_MOBILE_PHONE_NON_EDITABLE_TEXT') : ''}
                placeholderText={t('MPL_CREATE_ACCOUNT_MOBILE_PHONE')}
                supportedDialCodes={tempSupportedDialCodes}
              />
              <Input
                type="email"
                inputMode="email"
                placeholder={t('MPL_LOGIN_PLACEHOLDER_EMAIL')}
                onChange={value => {
                  setEmail(value.trim());
                }}
                value={email}
                maxLength={255}
                className={isFromInvitation ? styles.disabledInput : ''}
                dataTestId="mypages-login-email"
                autoComplete="username"
                minLengthErrorText={t('MPL_NOT_ENOUGH_CHARACTERS_ERROR')}
                mandatoryInfoText={t('MPL_ETC_MANDATORY_INPUT_FIELD')}
                hasError={emailError}
                errorMessage={emailErrorMsg}
                disabled={isFromInvitation}
              />
              <InputValidation
                inputToValidate={password}
                requirements={getPasswordRequirements(password)}
                errorList={getCurrentPasswordErrors(password)}
              >
                <PasswordInput
                  placeholder={t('MPL_LOGIN_PLACEHOLDER_PASSWORD')}
                  onChange={(value: string) => setPassword(value)}
                  value={password}
                  maxLength={30}
                  className={styles['login-field']}
                  dataTestId="mypages-login-password"
                  autoComplete="current-password"
                  hasError={passwordError}
                />
              </InputValidation>
              <PasswordInput
                placeholder={t('MPL_LOGIN_PLACEHOLDER_REPEAT_PASSWORD')}
                onChange={(value: string) => setRepeatPassword(value)}
                value={repeatPassword}
                maxLength={30}
                className={styles['login-field']}
                dataTestId="mypages-login-password-repeat"
                autoComplete="current-password"
                hasError={repeatPasswordError}
                errorMessage={repeatPasswordErrorMsg}
              />
              {!isFromInvitation && (
                <CountryPicker
                  entries={Object.entries(localeToCountryStringMapping) as LocaleToCountryStringMappingEntries[]}
                  onSelectionFieldChange={onSelectionFieldChange}
                  defaultValue={locale}
                  disabled={false}
                />
              )}

              <div className={styles.agreement}>
                <Checkbox dataTestId="placement-toggle-agreement" isChecked={agree} onToggle={() => setAgree(!agree)} />
                <p className={styles.agreementText}>
                  {t('MPL_TERMS_AND_CONDITIONS_AGREEMENT_INFO')}
                  <button
                    id="termsAndConditionsLink"
                    className={classNames(styles.linkText, styles.agreementLink)}
                    type="button"
                    onClick={() => setShowTerms(!showTerms)}
                  >
                    {t(`MPL_TERMS_AND_CONDITIONS`)}
                  </button>
                </p>
              </div>
              <p className={styles.privacyText}>
                {t('MPL_PRIVACY_POLICY_INFORMATION')}
                <button
                  type="button"
                  onClick={openPrivacyPolicy}
                  className={classNames(styles.linkText, styles.agreementLink)}
                >
                  {t('MPL_PRIVACY_POLICY')}
                </button>
              </p>
              {!isFromInvitation && <ReCaptcha onChange={setRecaptchaResponse} siteKey={reCaptchaSiteKey} />}
            </div>
            {errorMsg && <p className={styles.error}>{errorMsg}</p>}
            <Button
              text={t('MPL_SAVE')}
              className={styles['login-button']}
              red
              onClick={handleSubmit}
              dataTestId="mypages-create-submit"
              disabled={disableSubmit}
              isLoading={isCreatingAccount}
            />
            {!runningInApp && (
              <Button
                dataTestId="createAccountCancel"
                className={styles.cancelButton}
                text={t('MPL_CANCEL')}
                link
                onClick={() => navigateOrClose('/login')}
              />
            )}
          </main>
          <footer id="footer" className={classNames(styles.footer, cookieBannerVisible && styles.extraFooter)}>
            <LanguageMenu />
          </footer>
          <SidePanel visible={showTerms} onClose={() => setShowTerms(false)}>
            <TermsAndCondition
              terms={getAgreementByLocale(locale)}
              error={agreementError}
              setShowTerms={setShowTerms}
            />
          </SidePanel>
          <CookieInfo />
        </div>
      )}
    </>
  );
};

export default CreateAccount;
