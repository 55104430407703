import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ga } from 'mypages-library';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { getErrorMessage } from 'common/errors/errorUtils';
import { getLocale } from 'common/languageUtil';
import { authenticateWildfly } from 'common/wildflyAuthentication';
import { storeUsername } from 'gql/currentUser/currentUser';

export const useValidate = setValidateResponse => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language || 'se';

  const track = eventLabel => {
    ga.trackEvent({
      eventCategory: 'site login',
      eventAction: 'mfa validation',
      eventLabel,
    });
  };

  const handleError = e => {
    setIsLoading(false);
    setError(e.message);
    track('fail');
  };

  const handleValidateResponse = async response => {
    const reply = await response.json();
    if (response.status === 401) {
      setValidateResponse({ ...reply, errorCode: 'INVALID_TOKEN' });
      throw new Error(getErrorMessage('INVALID_TOKEN'));
    } else if (response.status !== 200) {
      setValidateResponse(reply);
      throw new Error(getErrorMessage());
    }
    return reply;
  };

  const validate = async (token, email) => {
    setIsLoading(true);
    const formData = new URLSearchParams();
    formData.append('token', token);
    try {
      const validateResponse = await fetchWithFailover('/auth/mfa/validate', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
        },
        body: JSON.stringify({
          token: `${token}`,
        }),
      });
      const validateBody = await handleValidateResponse(validateResponse);
      storeUsername(email);
      await authenticateWildfly(validateBody, email, getLocale(getLanguage()), setError, setIsLoading);
      setValidateResponse(validateBody);
      track('success');
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, validate };
};
