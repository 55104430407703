import { closeWebView } from 'mypages-library';
import { useLocation, useHistory } from 'react-router-dom';

const useNavigateOrClose = () => {
  const navigate = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const runningInApp = Boolean(searchParams.get('mm_app'));
  const navigateOrClose = (path: string) => {
    if (runningInApp) {
      return closeWebView();
    }
    navigate.push(path);
  };

  return { runningInApp, navigateOrClose };
};
export default useNavigateOrClose;
