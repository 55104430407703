/* eslint-disable react/jsx-fragments */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'mypages-library';
import styles from './Input.module.scss';

export const Input = ({
  placeholder,
  onChange,
  hasError,
  value,
  icon,
  disabled,
  type,
  inputMode,
  onClick,
  className,
  dataTestId,
  errorMessage,
  focus,
  minLength,
  minLengthErrorText,
  maxLength,
  onIconClick,
  disableLabelAnimation,
  autoComplete,
  mandatory,
  mandatoryInfoText,
}) => {
  const inputEl = useRef(null);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (focus && inputEl.current) {
      inputEl.current.focus();
    }
  }, [focus, inputEl.current]);

  const handleInputChange = input => {
    const inputValue = input.currentTarget.value;
    let isValid = true;
    setValidationError(null);
    if (minLength && inputValue.trim().length < minLength) {
      isValid = false;
      if (minLengthErrorText) {
        setValidationError(minLengthErrorText);
      }
    }
    onChange(inputValue, isValid);
  };

  const handleKeyPress = e => {
    // this is due to native keyboard in mobile/tablet need to close when pressing go/enter key
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const errorMsg = errorMessage || validationError;
  const fieldError = hasError || validationError;
  const placeholderText = mandatory ? `${placeholder}*` : placeholder;

  return (
    <>
      <div className={classNames(styles['input-container'], className)}>
        <label
          id={`${dataTestId}-label`}
          className={classNames(
            styles.wrapper,
            disabled && styles.disabled,
            fieldError && styles.error,
            icon !== '' && styles['with-icon'],
            (disableLabelAnimation || value !== '') && styles['has-value']
          )}
          onClick={onClick}
        >
          <input
            id={dataTestId}
            className={classNames(styles.input, disabled && styles.disabled)}
            type={type}
            inputMode={inputMode}
            value={value}
            onChange={handleInputChange}
            minLength={minLength}
            maxLength={maxLength}
            ref={inputEl}
            onKeyDown={handleKeyPress}
            disabled={disabled}
            autoComplete={autoComplete}
          />
          <p className={classNames(styles.label, type === 'password' && styles['label-for-password'])}>
            {placeholderText}
          </p>

          {icon && (
            <Icon
              icon={icon}
              className={classNames(styles['input-field-icon'], disabled && styles.disabled)}
              onClick={onIconClick}
              tabIndex={-1}
            />
          )}
        </label>
        {errorMsg && <p className={styles['error-message']}>{errorMsg}</p>}
        {mandatory && mandatoryInfoText && !errorMsg && <p className={styles.assistive}>{mandatoryInfoText}</p>}
      </div>
    </>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  value: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  errorMessage: PropTypes.string,
  focus: PropTypes.bool,
  minLength: PropTypes.number,
  minLengthErrorText: PropTypes.string,
  maxLength: PropTypes.number,
  onIconClick: PropTypes.func,
  disableLabelAnimation: PropTypes.bool,
  autoComplete: PropTypes.string,
  mandatory: PropTypes.bool,
  mandatoryInfoText: PropTypes.string,
};

Input.defaultProps = {
  hasError: false,
  value: '',
  icon: '',
  disabled: false,
  type: 'text',
  inputMode: 'text',
  onClick: () => {},
  onIconClick: () => {},
  className: '',
  dataTestId: null,
  errorMessage: '',
  focus: false,
  minLength: null,
  minLengthErrorText: null,
  maxLength: null,
  disableLabelAnimation: false,
  autoComplete: '',
  mandatory: false,
  mandatoryInfoText: null,
};
